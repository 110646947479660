import React, { createContext, useState } from 'react';
import PropTypes from 'prop-types';

export const ThemeContext = createContext();

export function ThemeProvider({ children }) {
  const [theme, setTheme] = useState('light');

  const contextValue = React.useMemo(() => ({ theme, setTheme }), [theme]);
  return (
    <ThemeContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={contextValue}
    >
      {children}
    </ThemeContext.Provider>
  );
}

ThemeProvider.defaultProps = {
  children: null,
};
ThemeProvider.propTypes = {
  children: PropTypes.node,
};
