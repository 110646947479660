/* eslint-disable import/prefer-default-export */
/* eslint-disable react/jsx-filename-extension */
import './src/assets/styles/main.scss';
import React from 'react';
import Layout from './src/layouts/Layout';
import { ThemeProvider } from './src/utilities/ThemeContext';
import SeoContextProvider from './src/utilities/SeoContextProvider';

export const wrapPageElement = ({ element, props }) => (
  <ThemeProvider>
    <SeoContextProvider>
      {/* eslint-disable-next-line react/jsx-props-no-spreading */}
      <Layout {...props}>{element}</Layout>
    </SeoContextProvider>
  </ThemeProvider>
);
