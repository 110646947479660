import React from 'react';
import PropTypes from 'prop-types';
import SiteTitle from '../components/SiteTitle/SiteTitle';
import useViewportHeight from '../hooks/useViewportHeight';

function Layout(props) {
  const {
    children,
  } = props;

  useViewportHeight();

  return (
    <div className="site-wrapper">
      <div className="site-header" id="site-header">
        <SiteTitle />
      </div>
      <div className="site-content" id="site-content">
        {children}
      </div>
      <div className="site-footer" id="site-footer" />
    </div>

  );
}

Layout.defaultProps = {
  children: React.Fragment,
};
Layout.propTypes = {
  children: PropTypes.element,
};
export default Layout;
