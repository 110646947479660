import { useEffect, useState } from 'react';
import { isBrowser } from '../utilities/utilities';

const useViewportHeight = () => {
  if (!isBrowser()) return;
  const [vh, setVh] = useState('1vh');

  useEffect(() => {
    const handleResize = () => {
      const clientHeight = document?.documentElement.clientHeight;
      requestAnimationFrame(() => {
        setVh(`${clientHeight * 0.01}px`);
      });
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    window.addEventListener('orientationchange', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      window.removeEventListener('orientationchange', handleResize);
    };
  }, []);

  useEffect(() => {
    const styleTag = document.createElement('style');
    const vhVar = `--vh: ${vh}`;
    styleTag.innerHTML = `
    :root{
        ${vhVar}
    }`;
    styleTag.id = 'viewportHeightStyle';
    document.head.appendChild(styleTag);

    return () => document.head.removeChild(styleTag);
  }, [vh]);
};

export default useViewportHeight;
