import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import * as styles from './SiteTitle.module.scss';
import { ThemeContext } from '../../utilities/ThemeContext';

function SiteTitle() {
  const { theme } = useContext(ThemeContext);
  const renderLogo = () => {
    if (theme === 'dark') {
      return (
        <>
          <StaticImage
            src="../../images/branding/logo-light.png"
            alt="Tomato"
            placeholder="NONE"
            layout="fixed"
            className={[styles.brandLogo, 'd-md-none'].join(' ')}
            height={28}
            key="small-light-logo"
          />
          <StaticImage
            src="../../images/branding/logo-light.png"
            alt="Tomato"
            placeholder="NONE"
            layout="fixed"
            className={[styles.brandLogo, 'd-none d-md-block'].join(' ')}
            height={40}
            key="medium-light-logo"
          />
        </>
      );
    }
    return (
      <>
        <StaticImage
          src="../../images/branding/logo.png"
          alt="Tomato"
          placeholder="NONE"
          layout="fixed"
          className={[styles.brandLogo, 'd-md-none'].join(' ')}
          height={28}
          key="small-dark-logo"
        />
        <StaticImage
          src="../../images/branding/logo.png"
          alt="Tomato"
          placeholder="NONE"
          layout="fixed"
          className={[styles.brandLogo, 'd-none d-md-block'].join(' ')}
          height={40}
          key="medium-dark-logo"
        />
      </>
    );
  };

  return (
    <div className={styles.header}>
      <div className="container-fluid">
        <div className="row">
          <div className="col-12">
            <h1 className={styles.siteTitle}>
              <Link to="/">{renderLogo()}</Link>
            </h1>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SiteTitle;
