exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-archive-jsx": () => import("./../../../src/templates/archive.jsx" /* webpackChunkName: "component---src-templates-archive-jsx" */),
  "component---src-templates-category-jsx": () => import("./../../../src/templates/category.jsx" /* webpackChunkName: "component---src-templates-category-jsx" */),
  "component---src-templates-home-jsx": () => import("./../../../src/templates/home.jsx" /* webpackChunkName: "component---src-templates-home-jsx" */),
  "component---src-templates-journal-jsx": () => import("./../../../src/templates/journal.jsx" /* webpackChunkName: "component---src-templates-journal-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-process-jsx": () => import("./../../../src/templates/process.jsx" /* webpackChunkName: "component---src-templates-process-jsx" */)
}

